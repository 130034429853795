import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoImage from "../components/logoimage"
import Footer from "../components/footer"
import { Hero, Navbar, Container, Columns, Form, Heading } from 'react-bulma-components'


class CalculatorPage extends React.Component {
  STATS_API = 'https://api.blockchain.info/stats';
  CURRENCY_API = "https://api.exchangeratesapi.io/latest?base=USD";

  state = { 
    BTCUSD: 4900, sBTCEUR: '',
    HASHRATE: 55000000, //Th
    MINERKW: 3.264, // kW
    MINERHASHRATE: 68, // Th,
    kosztkoparki: 3000,  // USD
    heatminercost: 2000,
    stats: {
      market_price_usd: 0,
      hash_rate: 0 // current estimated hashrate from blockchain.info
    },
    currencyrates: {},
    activeCurrency: 'EUR',
    
    cenaenergii: 0.075, // USD  
    pvUSD: 10000, // solar installation cost
    pvkW: 10, // solar average output
    pvkWyearlysurplus: 0,
    gasUSDkW: 0.06, 
    timeframe: 36, // months
    heatrequired: 10 // kW 
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value})
  }

  handleCurrencyChange = evt => {
    console.log(this.state.currencyrates)
    this.setState({activeCurrency: evt.target.name})
  }

  handleSubmit = () => {
    const { BTCUSD } = this.state
    this.setState({ sBTCUSD: BTCUSD })
  }


  async componentDidMount() {

    try {
      let [stats, currencyrates] = await Promise.all([
        fetch(this.STATS_API),
        fetch(this.CURRENCY_API)
      ])
      stats = await stats.json();
      currencyrates = await currencyrates.json();
      this.setState({ stats: stats })
      this.setState({ currencyrates: currencyrates.rates })
      this.setState({ BTCUSD: stats.market_price_usd })   
      this.setState({ HASHRATE: (stats.hash_rate / 1000).toFixed() })
    }
    catch(err) {
      console.log(err);
    };
  }

  render() {
    const { 
      BTCUSD, 
      HASHRATE, MINERKW, MINERHASHRATE, 
      kosztkoparki, heatminercost, stats, currencyrates, activeCurrency,
      cenaenergii, pvkWyearlysurplus, gasUSDkW, timeframe, heatrequired,
    } = this.state
    
    const currencies = ['USD', 'EUR', 'PLN'];

    let minerBTCdaily = MINERHASHRATE / HASHRATE * stats.miners_revenue_btc,
        
        BTCactiveCurrency = (BTCUSD * currencyrates[activeCurrency]).toFixed(2),
        minerBTCmonthly = minerBTCdaily * 365/12, 
        minerBTCmonths = minerBTCmonthly * timeframe,
        
        minerkWdaily = MINERKW * 24,
        minerkWmonthly = minerkWdaily * 365/12,
        minerkWmonths = minerkWmonthly * timeframe,

        minerCurrencydaily = minerBTCdaily * BTCactiveCurrency,
        minerCurrencymonthly = minerBTCmonthly * BTCactiveCurrency,
        minerCurrencymonths = minerBTCmonths * BTCactiveCurrency,

        minerkWUSDdaily = (MINERKW - (pvkWyearlysurplus / 365 / 24)) * 24 * cenaenergii,
        minerkWUSDmonthly = minerkWUSDdaily * 365/12,
        minerkWUSDmonths = minerkWUSDmonthly * timeframe,
        
        gaskWUSDdaily = (heatrequired - MINERKW) * gasUSDkW * 24,
        gaskWUSDmonthly = gaskWUSDdaily * 365/12,
        gaskWUSDmonths = gaskWUSDmonthly * timeframe,
        gasprofit = MINERKW * gasUSDkW * 24 * 365/12 * timeframe,

        initialcost = Number(kosztkoparki) + Number(heatminercost),
        profit = minerCurrencymonths - initialcost - minerkWUSDmonths + gasprofit,
        devicevalue = (Number(kosztkoparki) + Number(heatminercost)) * (Math.pow(0.978, timeframe)),
        total = profit + devicevalue;
        ; 

          console.log(kosztkoparki);


    return (
      <Layout>
        <Seo title="Heatminer calculator" keywords={[`calculator`]} 
          description="calculate profitability of HeatMiner taking into account current heating costs and access to solar generated power"
        />
        <Hero>
          <Hero.Header renderAs="header">
            <Container>
              <Navbar>
                <Navbar.Brand>
                  <Navbar.Item href='/'>
                    <LogoImage type="green" />
                  </Navbar.Item>
                </Navbar.Brand>
              </Navbar>
            </Container>
          </Hero.Header>
          <Hero.Body>
            <Container>

            
            <Heading subtitle>Profitability calculator</Heading>
            
              <Navbar>
                <Navbar.Brand>
                  <Navbar.Item renderAs='text'>Select currency:</Navbar.Item>
                  <Navbar.Burger />
                </Navbar.Brand>
                <Navbar.Menu>
                  <Navbar.Container>
                    {currencies.map((currency, index) => {
                        return (
                          <Navbar.Item 
                            key={index} 
                            active={currency === activeCurrency}
                            name={currency}                            
                            onClick={this.handleCurrencyChange}                            
                          >
                            {currency}
                          </Navbar.Item>
                        )
                    })}
                  </Navbar.Container>
                </Navbar.Menu>
              </Navbar>

              <Columns>
                <Columns.Column>
                  <Heading renderAs="p" heading>BTC/{activeCurrency}</Heading><Heading renderAs="p">{BTCactiveCurrency}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Network Hashrate Th/s</Heading><Heading renderAs="p">{(stats.hash_rate / 1000).toFixed(0)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Mined BTC/24h</Heading><Heading renderAs="p">{stats.miners_revenue_btc}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>EUR/USD</Heading><Heading renderAs="p">{currencyrates.EUR}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>EUR/PLN</Heading><Heading renderAs="p">{currencyrates.PLN}</Heading>
                </Columns.Column>
                <Columns.Column>

                </Columns.Column>
              </Columns>

              

              <Columns>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Price (BTC/{activeCurrency})</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='BTCUSD' value={BTCUSD} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Network Hashrate (Th/s)</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='HASHRATE' value={HASHRATE} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>{`Device power (kW)`}</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='MINERKW' value={MINERKW} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Miner Hashrate (Th/s)</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='MINERHASHRATE' value={MINERHASHRATE} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Miner cost (USD)</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='kosztkoparki' value={kosztkoparki} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Greenheat (USD)</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='heatminercost' value={heatminercost} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>


              <Columns>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Electricy price ({activeCurrency}/kWh)</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" name='cenaenergii' value={cenaenergii} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>

                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Solar surplus (kW/YEAR)</Form.Label>
                    <Form.Control><Form.Input type="number" name='pvkWyearlysurplus' value={pvkWyearlysurplus} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Heating cost ({activeCurrency}/kWh)</Form.Label>
                    <Form.Control><Form.Input type="text" name='gasUSDkW' value={gasUSDkW} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Heat required (kW)</Form.Label>
                    <Form.Control><Form.Input type="text" name='heatrequired' value={heatrequired} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Timeframe (months)</Form.Label>
                    <Form.Control><Form.Input type="text" name='timeframe' value={timeframe} onChange={this.handleChange} />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                

              </Columns>
              
              

              <Heading subtitle>Mining income</Heading>

              <Columns>
                <Columns.Column>
                  <Heading renderAs="p" heading>Proceeds (BTC/DAY)</Heading><Heading renderAs="p">{(minerBTCdaily).toFixed(6)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Proceeds (BTC/MONTH)</Heading><Heading renderAs="p">{(minerBTCmonthly).toFixed(6)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Proceeds (BTC/TIME)</Heading><Heading renderAs="p">{(minerBTCmonths).toFixed(6)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Proceeds ({activeCurrency}/DAY)</Heading><Heading renderAs="p">{(minerCurrencydaily).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Proceeds ({activeCurrency}/MONTH)</Heading><Heading renderAs="p">{(minerCurrencymonthly).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Proceeds ({activeCurrency}/TIME)</Heading><Heading renderAs="p">{(minerCurrencymonths).toFixed(2)}</Heading>
                </Columns.Column>
              </Columns>

              <Heading subtitle>Energy consumption and costs</Heading>

              <Columns>
                <Columns.Column>
                  <Heading renderAs="p" heading>Consumption (kW/DAY)</Heading><Heading renderAs="p">{(minerkWdaily).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Consumption (kW/MONTH)</Heading><Heading renderAs="p">{(minerkWmonthly).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Consumption (kw/TIME)</Heading><Heading renderAs="p">{(minerkWmonths).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Consumption ({activeCurrency}/DAY)</Heading><Heading renderAs="p">{(minerkWUSDdaily).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Consumption ({activeCurrency}/MONTH)</Heading><Heading renderAs="p">{(minerkWUSDmonthly).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Consumption ({activeCurrency}/TIME)</Heading><Heading renderAs="p">{(minerkWUSDmonths).toFixed(2)}</Heading>
                </Columns.Column>
              </Columns>
              <Heading subtitle>Heat generation / recovery</Heading>

              <Columns>
                <Columns.Column>
                  <Heading renderAs="p" heading>Heat generated (kW/DAY)</Heading><Heading renderAs="p">{(minerkWdaily).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Heat generated (kW/MONTH)</Heading><Heading renderAs="p">{(minerkWmonthly).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Heat generated (kw/TIME)</Heading><Heading renderAs="p">{(minerkWmonths).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Heating ({activeCurrency}/DAY)</Heading><Heading renderAs="p">{(gaskWUSDdaily).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Heating ({activeCurrency}/MONTH)</Heading><Heading renderAs="p">{(gaskWUSDmonthly).toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Heating ({activeCurrency}/TIME)</Heading><Heading renderAs="p">{(gaskWUSDmonths).toFixed(2)}</Heading>
                </Columns.Column>
              </Columns>

              <Heading subtitle>Total cost and profit</Heading>

              <Columns>
                <Columns.Column>
                  <Heading renderAs="p" heading>Initial cost ({activeCurrency})</Heading><Heading renderAs="p">{initialcost.toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                <Heading renderAs="p" heading>Final device value ({activeCurrency})</Heading><Heading renderAs="p">{devicevalue.toFixed(2)}</Heading>
                </Columns.Column>
                
                <Columns.Column>
                  <Heading renderAs="p" heading>Total income ({activeCurrency}/TIME)</Heading><Heading renderAs="p">{profit.toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                  <Heading renderAs="p" heading>Savings ({activeCurrency}/TIME)</Heading><Heading renderAs="p">{total.toFixed(2)}</Heading>
                </Columns.Column>
                <Columns.Column>
                </Columns.Column>
                
                <Columns.Column>
                  
                </Columns.Column>
              </Columns>

            </Container>
          </Hero.Body>
        </Hero>
        <Footer />
      </Layout>
    )
  }
}


export default CalculatorPage
